import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSmartDevices(ctx, params) {
      return useJwt.getSmartDevices(params).then(response => response);
    },
    getSmartDeviceDetail(ctx, params) {
      return useJwt.getSmartDeviceDetail(params).then(response => response);
    },
    createSmartDevice(ctx, data) {
      return useJwt.createSmartDevice(data).then(response => response);
    },
    updateSmartDevice(ctx, data) {
      return useJwt.updateSmartDevice(data).then(response => response);
    },
    deleteSmartDevices(ctx, data) {
      return useJwt.deleteSmartDevices(data).then(response => response);
    },
    syncVConnexAccount(ctx, data) {
      return useJwt.syncVConnexAccountWithCode(data).then(response => response);
    },
    fetchVConnexAuthorizationUrl(ctx, data) {
      return useJwt.fetchVConnexAuthorizationUrl(data).then(response => response);
    },
    linkSmartDevice(ctx, data) {
      return useJwt.linkSmartDevice(data).then(response => response);
    },
    getTuyaAccount(ctx, data) {
      return useJwt.getTuyaAccount(data).then(response => response);
    },
    syncTuyaDevices(ctx, data) {
      return useJwt.syncTuyaDevices(data).then(response => response);
    },
    changeDeviceStatus(ctx, data) {
      return useJwt.changeDeviceStatus(data).then(response => response);
    },
  },
};
